import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Hero from '../../components/hero'
import Formulier from '../../containers/formio-form'
import 'formiojs/dist/formio.builder.min.css'

const FormulierPagina = ({ location, pageContext }) => {
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
    thankYouMessage,
  } = pageContext
  const { externalUrl, name } = pageContext.form
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => setFlyoutOpen((prevState) => !prevState)

  return (
    <Layout location={location}>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        url={location.href}
        metadata={pageContext.metadata}
      />
      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
          dot
        />
        <Formulier
          formSrc={externalUrl}
          title={name}
          thankYouMessage={thankYouMessage}
        />
      </main>
      <Footer />
    </Layout>
  )
}

FormulierPagina.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default FormulierPagina
