import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import Heading from '../../components/ui/heading'
import { Row, Col } from '../../components/ui/wrapper'
import { SectionWrap, FormWrapper, LoaderContainer, Loader } from './style'
import RichText from '../../components/ui/richt-text'

// Only load the form component in the browser
const Form = loadable(() => import('@formio/react/lib/components/Form'), {
  ssr: false,
})

const Formulier = ({ formSrc, title, thankYouMessage }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isGreatSuccess, setIsGreatSuccess] = useState(false)
  const onFormRender = useCallback(() => {
    setIsLoading(false)
  }, [])

  const onSubmitDone = () => setIsGreatSuccess(true)

  return (
    <SectionWrap className="container-xl">
      <FormWrapper>
        <Row>
          <Col md={12}>
            {title && (
              <Heading as="h2" mb="16px">
                {title}
              </Heading>
            )}
            {!isLoading && !isGreatSuccess && (
              <>Velden met een * zijn verplicht.</>
            )}
            {isLoading && (
              <>
                <LoaderContainer>
                  Het formulier wordt geladen, een moment geduld alstublieft.
                  <Loader />
                </LoaderContainer>
              </>
            )}
            {/* Render the form only when the thank you page is not shown */}
            {!isGreatSuccess && (
              <Form
                src={formSrc}
                options={{
                  language: 'nl',
                  noAlerts: true,
                  buttonSettings: {
                    showCancel: true,
                    showPrevious: true,
                    showNext: true,
                    showSubmit: false
                  },
                  i18n: {
                    nl: {
                      words: 'woorden',
                      characters: 'tekens',

                      'File Name': 'Bestandsnaam',
                      Size: 'Grootte',
                      'Drop files to attach,':
                        'Sleep bestanden om ze toe te voegen,',
                      or: 'of',
                      browse: 'selecteer een bestand',
                      // Start of ... https://github.com/formio/formio.js/blob/master/src/translations/en.js
                      unsavedRowsError: 'Sla alle velden op.',
                      invalidRowsError: 'Corrigeer de fout ingevulde velden.',
                      invalidRowError:
                        'Ongeldig veld. Corrigeer of verwijder het.',
                      alertMessageWithLabel: '{{label}}: {{message}}',
                      alertMessage: '{{message}}',
                      complete: 'Aanmelding voltooid.',
                      error:
                        'Corrigeer de foutief ingevulde velden voordat u deze indient.',
                      errorListHotkey:
                        'Druk op Ctrl + Alt + X om terug te gaan naar de foutenlijst.',
                      errorsListNavigationMessage:
                        'Klik om naar het veld met de volgende fout te gaan.',
                      submitError:
                        'Controleer het formulier en corrigeer alle fouten voordat u het formulier indient.',
                      required: '{{field}} Corrigeer de fout ingevulde velden.',
                      unique: '{{field}} moet uniek zijn.',
                      array: '{{field}} bevat een ongeldige waarde',
                      array_nonempty: '{{field}} is verplicht.', // eslint-disable-line camelcase
                      nonarray: '{{field}}',
                      select: '{{field}} bevat een ongeldige selectie.',
                      pattern:
                        '{{field}} komt niet overeen met het patroon {{pattern}}',
                      minLength:
                        '{{field}} moet minstens {{length}} karakters bevatten.',
                      maxLength:
                        '{{field}} moet meer dan {{length}} karakters bevatten.',
                      minWords:
                        '{{field}} moet minstens {{length}} woorden bevatten.',
                      maxWords:
                        '{{field}} moet meer dan {{length}} woorden bevatten.',
                      min: '{{field}} kan niet lager zijn dan {{min}}.',
                      max: '{{field}} kan niet groter zijn dan {{max}}.',
                      maxDate:
                        '{{field}} mag geen datum zijn later dan {{- maxDate}}',
                      minDate:
                        '{{field}} mag geen datum zijn voor {{- minDate}}',
                      maxYear: '{{field}} mag niet later zijn dan {{maxYear}}',
                      minYear: '{{field}} mag niet eerder zijn dan {{minYear}}',
                      invalid_email:
                        '{{field}} moet een geldig e-mailadres zijn.', // eslint-disable-line camelcase
                      invalid_url: '{{field}} moet een geldige url zijn.', // eslint-disable-line camelcase
                      invalid_regex:
                        '{{field}} komt niet overeen met het patroon. {{regex}}.', // eslint-disable-line camelcase
                      invalid_date: '{{field}} is geen geldige datum.', // eslint-disable-line camelcase
                      invalid_day: '{{field}} is geen geldige dag.', // eslint-disable-line camelcase
                      mask: '{{field}} komt niet overeen met het masker.',
                      valueIsNotAvailable:
                        '{{ field }} is een ongeldige waarde.',
                      stripe: '{{stripe}}',
                      month: 'Maand',
                      day: 'Dag',
                      year: 'Jaar',
                      january: 'Januari',
                      february: 'Februari',
                      march: 'Maart',
                      april: 'April',
                      may: 'Mei',
                      june: 'Juni',
                      july: 'Juli',
                      august: 'Augustus',
                      september: 'September',
                      october: 'Oktober',
                      november: 'November',
                      december: 'December',
                      next: 'Volgende',
                      previous: 'Vorige',
                      cancel: 'Annuleren',
                      submit: 'Verzenden',
                      confirmCancel: 'Weet je zeker dat je wilt annuleren?',
                      saveDraftInstanceError:
                        'Kan concept niet opslaan omdat er geen Form.io-instantie is.',
                      saveDraftAuthError:
                        'Kan concept niet opslaan tenzij een gebruiker is geverifieerd.',
                      restoreDraftInstanceError:
                        'Kan concept niet opslaan omdat er geen Form.io-instantie is.',
                      time: 'Ongeldige tijd',
                      cancelButtonAriaLabel:
                        'Annuleren. Klik om het formulier te resetten.',
                      previousButtonAriaLabel:
                        'Vorige. Klik om naar de vorige tab te gaan.',
                      nextButtonAriaLabel:
                        'Volgende. Klik om naar de volgende tab te gaan.',
                      submitButtonAriaLabel:
                        'Verzenden. Klik om het formulier in te dienen.',
                    },
                  },
                }}
                onRender={onFormRender}
                onSubmitDone={onSubmitDone}
              />
            )}

            {isGreatSuccess && thankYouMessage && (
              <RichText json={thankYouMessage.json} />
            )}
            {isGreatSuccess && !thankYouMessage && (
              <p>Bedankt voor het invullen van het {title} formulier</p>
            )}
          </Col>
        </Row>
      </FormWrapper>
    </SectionWrap>
  )
}

Formulier.propTypes = {
  formSrc: PropTypes.string,
  title: PropTypes.string,
}

export default Formulier
