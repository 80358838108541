import styled from 'styled-components'
import { Container } from '../../components/ui/wrapper'
import { device } from '../../theme'

export const SectionWrap = styled.section`
  position: relative;
  z-index: 5;
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 150px;

  @media ${device.medium} {
    padding: 0;
  }
`

export const Loader = styled.div`
  margin: 20px auto 0;
  border-radius: 80%;
  display: block;
  height: 50px;
  width: 50px;
  position: relative;
  animation: spin 0.675s linear 0s infinite normal;
  background: ${(props) => props.theme.colors.white};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &:before {
    border-radius: 0 90px 90px 0;
    height: 50px;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 1;
    background: ${(props) => props.theme.colors.theme};
    background-image: linear-gradient(
      ${(props) => props.theme.colors.white},
      ${(props) => props.theme.colors.theme}
    );
  }

  &:after {
    border-radius: 80%;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: ${(props) => props.theme.colors.white};
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const LoaderContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`

export const FormWrapper = styled(Container)`
  position: relative;
  top: -100px;
  max-width: 970px !important;
  margin: 0 auto;
  padding: 100px 60px 90px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 5;

  @media ${device.large} {
    top: -30px;
    padding: 40px;
  }

  @media ${device.medium} {
    top: 0;
  }

  @media ${device.small} {
    padding: 30px;
  }

  @media print {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0;
  }

  > * {
    max-width: 680px;
  }

  h2 {
    padding-top: 24px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: auto;
      height: 2px;
      width: 60px;
      background-color: ${(props) => props.theme.colors.theme};
    }
  }

  small {
    display: block;
  }

  .radio {
    display: flex;
    align-items: baseline;

    input {
      min-height: 0;
      margin-right: 8px;
    }

    label {
      margin-right: 30px;
    }
  }

  .checkbox {
    position: relative;

    input {
      position: absolute;
      top: 12px;
      left: 0;
      min-height: 0;
      margin: 0;
    }

    label {
      margin-bottom: 40px;
      padding-bottom: 40px;
      padding-left: 25px;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }
  }

  .checkbox + .checkbox label {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  a {
    text-decoration: underline;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  .btn {
    font-family: Dia;
    font-size: 16px;
    font-weight: normal;
    padding: 0 36px;
    text-align: center;
    line-height: 44px;
    height: 48px;
    font-family: ${(props) => props.theme.fontFamily.heading};
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    transform: translateY(0px);
    border-radius: 35px;

    &.btn-primary {
      background: ${(props) => props.theme.colors.theme};
      color: ${(props) => props.theme.colors.white};

      &:hover,
      &:focus {
        color: ${(props) => props.theme.colors.theme};
        background: transparent;
        border-color: ${(props) => props.theme.colors.theme};
      }
    }

    &.btn-wizard-nav-previous,
    &.btn-danger,
    &.editRow {
      color: ${(props) => props.theme.colors.theme};
      background: transparent;
      border-color: ${(props) => props.theme.colors.theme};

      &:hover,
      &:focus {
        background: ${(props) => props.theme.colors.theme};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  /* Specific Form.io styling */

  /* Base input styling for all currently used form inputs and 'formio inputs' */
  input,
  textarea,
  .form-control.dropdown,
  .formio-component-select .form-control {
    & :not(.formio-select-autocomplete-input) {
      min-height: 48px;
      border-color: ${(props) => props.theme.colors.borderDark};
    }
  }

  /* Styling for formio html5 'select' fields */
  .formio-component-select {
    .form-control {
      &:focus {
        background: ${(props) => props.theme.colors.white}
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFAgMAAAABCpX7AAAAAXNSR0IB2cksfwAAAAlQTFRFAAAAmZmZlZWV0t8miwAAAAN0Uk5TAP+AhOq6jAAAABxJREFUeJxjmBqawKAa2sDAGcbAwBTBwMCwgAEAK+sDnl+mDOkAAAAASUVORK5CYII=)
          no-repeat center right 20px !important;
      }
    }
  }

  /* Styling for formio 'select' fields */
  .form-control {
    &:focus {
      color: #495057;
      border-color: ${(props) => props.theme.colors.theme};
    }
    &.dropdown {
      display: flex;
      align-items: center;
    }
  }

  /* Styling for form tab control */
  .pagination {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};

    .page-item {
      .page-link {
        padding: 0.75rem;
        border: none;
        background: transparent;
        color: #444;

        &::before {
          content: '';
          width: 0;
          height: 3px;
          bottom: -1px;
          position: absolute;
          left: 0;
          background-color: #d90024;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &:hover {
          &::before {
            width: 100%;
            left: 0;
          }
        }
      }
      &.active {
        .page-link {
          border: none;
          border-radius: 0;
          background: transparent !important;
          color: ${(props) => props.theme.colors.theme} !important;

          &::before {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }

  /* Styling for content fields */
  .formio-component {
    &.formio-component-content {
      margin-bottom: 2rem;
    }
  }

  /* Error styling for form controls container (next / previous / submit) */
  .formio-wizard-nav-container {
    margin-top: 4rem;

    &.list-inline {
      align-items: center;
      justify-content: flex-end;
    }

    .btn-wizard-nav-cancel {
      display: none;
      width: 0px;
      height: 0px;
    }
  }

  /* Error styling for field error message */
  .field-required:after,
  .tab-error::after {
    color: inherit;
  }

  /* Error styling for field wrappers */
  .formio-error-wrapper {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: 0;

    .formio-errors {
      .error {
        color: red;
      }
    }
  }

  /* Styling for formio panels */
  .formio-component-panel {
    .card-header {
      background: none;
    }
    .card-title {
      color: ${(props) => props.theme.colors.theme};
    }
  }

  .formio-button-remove-row {
    position: relative;
    padding: 0.75rem;
    width: 40px;
    height: 40px;
  }

  /* Styling for fontawesome icons */
  .fa {
    &.fa-times-circle-o {
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 11px;
        background-color: ${(props) => props.theme.colors.white};
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: top left;
        content: '';
      }
      &:after {
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }
  }

  /* Styling for List group */
  .list-group {
    .list-group-item {
      .row {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  /* Styling for Day component */
  .formio-component-day {
    .col {
      flex-basis: unset;
      flex-grow: unset;

      &:not(:first-child) {
        margin-top: 0.75rem;
      }

      @media ${device.smallOnly} {
        flex-basis: 0;
        flex-grow: 1;

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

  /* Styling for Table component */
  .table-responsive {
    display: block;
    overflow-x: auto;

    table {
      table-layout: fixed;

      .form-group {
        label {
          padding-top: 0;
        }
      }
    }

    &.table--activity-budget {
      table {
        width: 300%;
        @media ${device.smallOnly} {
          width: 200%;
        }
      }
    }
    &.table--overhead-cost {
      table {
        width: 200%;
        @media ${device.smallOnly} {
          width: 100%;
        }
      }
    }
    &.table--benefits {
      table {
        width: 200%;
        @media ${device.smallOnly} {
          width: 100%;
        }
      }
    }
  }
`
